<!-- eslint-disable -->

<template >
</template>

<script>
/* eslint-disable */
export default {
  name: 'TechNotes',
  components: {
  },
  data() {
    return {
      example1Form: {
        src: "https://docs.google.com/document/d/e/2PACX-1vRKyz6WZ2Ezv0t1gNLh5D7kKLvDSHYFTjqcUSz2n3sjLz2D6nrJMfSMzO3MsDkdqXtvwmToifhiHDwI/pub?embedded=true",
      },
      iframeLoading: true
    }
  },

  methods: {
    onLoad() {
      // console.log('iframe loaded');

      this.iframeLoading = false;
    },
    onIframeLoad() {
      // console.log('iframe loaded');
    }
  }


}
</script>

<style lang="less">
    .iframe-wrapper {
        border: 1px solid gray;
        margin: 15px;
        height: 2300px;
        width: 900px;

        .vue-friendly-iframe {
            height: 100%;
            width: 100%;
            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }
</style>
